import { Link } from "react-router-dom";
import HubSpotChat from "../HubSpotChat/HubSpotChat";
import React from "react";

import "./Footer.scss";

const Footer = () => {
  HubSpotChat();
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__copyright">
          © Tribaja {currentYear}
          <div className="footer__links">
            <Link to={"https://tribaja.co/terms-and-conditions"}>Terms and Conditions</Link>
            <Link to={"https://tribaja.co/privacy-policy"}>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import {FC, ReactNode, CSSProperties} from "react";
import "./Button.scss";

interface ButtonProps {
    children: React.ReactNode;
    type?: "INVERTED" | "PRIMARY";
    style?: React.CSSProperties;
    className?: string;
    backgoundColor?: string;
    onClick?: () => void;
    disabled?: boolean;
}

const Button: FC<ButtonProps> = ({
                                     children,
                                     type = "PRIMARY",
                                     style,
                                     className,
                                     backgoundColor,
                                     disabled,
                                     onClick,
                                 }) => {
    const updatedStyle: CSSProperties = {...style};

    if (backgoundColor) {
        updatedStyle.backgroundColor = backgoundColor;
    }
    return (
        <button
            className={`${type === "PRIMARY" ? "btn-primary" : "btn-inverted"} ${
                className ? className : ""
            } ${disabled ? "btn-disabled" : ""}`}
            style={updatedStyle}
            onClick={() => {
                console.log("Button component clicked");
                if (onClick) onClick();
            }}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;

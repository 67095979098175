import { useEffect } from "react";

import "./HubSpotchat.scss";

const HubSpotChat = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//js-na1.hs-scripts.com/7279073.js";
        script.async = true;
        script.defer = true;
        script.id = "hs-script-loader";
        document.body.appendChild(script);
    }, []);
};

export default HubSpotChat;
